<template>
  <vx-card actionable class="cardx" title="Merge Leads">
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-input
          class="inputx"
          style="margin: 1%"
          placeholder="Retain Can-ID"
          v-model="retainLead"
        />
        <vs-input
          class="inputx"
          style="margin: 1%"
          placeholder="Delete Can-ID"
          v-model="deleteLead"
        />
        <vs-button
          color="rgb(134, 4, 98)"
          style="margin: 1%"
          type="filled"
          @click="getDetails"
          >Get Details</vs-button
        >
      </vs-col>
      <!-- <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
     
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
   
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3"></vs-col> -->
    </vs-row>
    <vs-row style="margin-bottom: 3%" v-if="infoFetched">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-button color="dark" type="filled" @click="mergeLeads()"
          >Merge Leads</vs-button
        >
      </vs-col>
    </vs-row>
    <vs-row v-show="errordiv">
      <vs-col
        vs-offset="2"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="8"
      >
        <vs-card>
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="start"
              vs-align="center"
              vs-w="12"
            >
              <vs-chip color="danger">
                Error
              </vs-chip>
            </vs-col> </vs-row
          ><br />
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <p style="color:red;font-size:small;">{{ mergeLeadError }}</p>
            </vs-col> </vs-row
          ><br />
          <vs-row>
            <vs-col
              vs-offset="2"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="8"
            >
              <vs-button
                color="dark"
                size="small"
                style="font-size: xx-small;"
                type="filled"
                @click="RemoveErrordiv()"
                >OK</vs-button
              >
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>

    <vs-row style="margin-bottom: 3%" v-if="infoFetched">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <div class="boxed">
          <vs-row style="margin-bottom: 3%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <p class="heading">Retain Lead Details</p>
            </vs-col>
          </vs-row>
          <div v-if="retain_lead_details !== null">
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Name:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ retain_lead_details.name }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Can-ID:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ retain_lead_details.identity }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Level:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ retain_lead_details.level }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Company:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ retain_lead_details.company }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>CM Spoc:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ retain_lead_details.cm_spoc }}</b>
                </div>
              </vs-col>
            </vs-row>

            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>IIML Spoc:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ retain_lead_details.iiml_spoc }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Engagement Details:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{
                    retain_lead_details.engagement_details
                  }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <div style="display:grid">
                  <b>Mobiles:</b>
                  <div
                    style="margin-left: 2%"
                    v-for="(mobile, index) in retain_lead_details.mobiles"
                    :key="index"
                  >
                    <b style="color: gray">{{ hidePhoneNumber(mobile.phone_number) }},</b>
                  </div>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Emails:</b>
                <div
                  style="margin-left: 2%"
                  v-for="(email, index) in retain_lead_details.emails"
                  :key="index"
                >
                  <b style="color: gray">{{ hideEmail(email.email) }}</b>
                </div>
                ,
              </vs-col>
            </vs-row>
          </div>
          <div v-else>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <p class="heading">Does Not Exist</p>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <div class="boxed">
          <vs-row style="margin-bottom: 3%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <p class="heading">Delete Lead Details</p>
            </vs-col>
          </vs-row>
          <div v-if="delete_lead_details !== null">
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Name:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ delete_lead_details.name }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Can-ID:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ delete_lead_details.identity }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Level:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ delete_lead_details.level }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Company:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ delete_lead_details.company }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>CM Spoc:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ delete_lead_details.cm_spoc }}</b>
                </div>
              </vs-col>
            </vs-row>

            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>IIML Spoc:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{ delete_lead_details.iiml_spoc }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Engagement Details:</b>
                <div style="margin-left: 2%">
                  <b style="color: gray">{{
                    delete_lead_details.engagement_details
                  }}</b>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <div style="display:grid">
                  <b>Mobiles:</b>
                  <div
                    style="margin-left: 2%"
                    v-for="(mobile, index) in delete_lead_details.mobiles"
                    :key="index"
                  >
                    <p>{{ hidePhoneNumber(mobile.phone_number) }},</p>
                  </div>
                </div>
              </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <b>Emails:</b>
                <div
                  style="margin-left: 2%"
                  v-for="(email, index) in delete_lead_details.emails"
                  :key="index"
                >
                  <b style="color: gray">{{ hideEmail(email.email) }}</b>
                </div>
                ,
              </vs-col>
            </vs-row>
          </div>
          <div v-else>
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <p class="heading">Does Not Exist</p>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";

import swal from "sweetalert2/src/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  data() {
    return {
      errordiv: false,
      retainLead: null,
      deleteLead: null,
      infoFetched: false,
      retain_lead_details: null,
      delete_lead_details: null,
      mergeLeadError: "",
    };
  },
  methods: {
    hideEmail(value){
      var maskedid = "";
      var index = value.lastIndexOf("@");
      var prefix = value.substring(0, index);
      var postfix = value.substring(index);

      var mask = prefix.split('').map(function(o, i) {
        if (i == 0 || i == (index - 1)) {
          return o;
        } else {
          return '*';
        }
      }).join('');

      maskedid = mask + postfix;
      return maskedid;
    },
    hidePhoneNumber(value){
      if(value != undefined && value != '' && value != null){
        var number = value
        var mobile = ''
        if(number.slice(0,1)=='+'){
          mobile = number.substring(3)
          var phone = mobile.replace(mobile.substring(0,6), 'XXXXXX');
          return phone;
        }else{
          return number.replace(number.substring(0,6), 'XXXXXX');
        }
      }
    },
    getDetails() {
      if (this.retainLead === null || this.deleteLead === null) {
        this.showNotification(
          "Both the fields should have an input",
          "Fill all the required details",
          "danger"
        );
        return;
      }
      this.$vs.loading();
      let params = {
        retain_lead: this.retainLead,
        delete_lead: this.deleteLead,
      };
      axios
        .get(`${constants.SERVER_API}mergeLeadDetails`, {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          if (response.data.status !== "success") {
            this.infoFetched = false;
            this.showNotification(
              response.data.status,
              response.data.message,
              "danger"
            );
          } else {
            this.handleResponse(response);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    mergeLeads() {
      swal
        .fire({
          title: "Are you sure?",
          text:
            "You want to merge this " +
            this.deleteLead +
            "  lead to " +
            this.retainLead +
            "  lead ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, merge it!",
        })
        .then((result) => {
          if (result.value) {
            this.$vs.loading();
            let params = {
              retain_identity: this.retainLead,
              delete_identity: this.deleteLead,
            };
            axios
              .get(`${constants.SERVER_API}mergeLeads`, {
                params: params,
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
              })
              .then((response) => {
                this.$vs.loading.close();

                if (response.data.status !== "success") {
                  this.mergeLeadError = response.data.message;
                  this.errordiv = true;
                } else {
                  this.handleNotification(response);
                  // this.getLeadsAfterMerge(this.retainLead);
                  this.retain_lead_details = response.data.new_lead_details;
                  this.delete_lead_details = null;
                  this.retainLead = "";
                  this.deleteLead = "";
                }
              })
              .catch((error) => {
                this.$vs.loading.close();
                this.handleError(error);
              });
          }
        });
    },
    getLeadsAfterMerge(retainLead) {
      this.$vs.loading();
      let params = {
        retain_lead: retainLead,
      };
      axios
        .get(`${constants.SERVER_API}mergeLeadDetails`, {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          if (response.data.status !== "success") {
            this.showNotification(
              response.data.status,
              response.data.message,
              "danger"
            );
          } else {
            this.handleResponse(response);
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    RemoveErrordiv() {
      this.errordiv = false;
    },
    handleResponse(response) {
      this.retain_lead_details = response.data.data.retain_lead_details;
      this.delete_lead_details = response.data.data.delete_lead_details;
      this.infoFetched = true;
      console.log(response);
    },
    submit() {},
    refresh() {
      this.retainLead = null;
      this.deleteLead = null;
      this.retain_lead_details = null;
      this.delete_lead_details = null;
    },
    showNotification(title, text, color) {
      this.$vs.notify({
        title: title,
        text: text,
        color: color,
      });
    },
  },
};
</script>

<style>
.boxed {
  border: 1px solid gray;
  margin-bottom: 1%;
  width: 100%;
  height: 100%;
}
.heading {
  color: gray;
  font-size: 20px;
  font-weight: bold;
}
</style>
